<template>
  <button class="btn btn-primary btn-fab" @click="$emit('update:add')">
    <i class="fas fa-plus" v-if="!isLoading"> Añadir {{ text }}</i>
    <span v-if="isLoading" :disabled="isLoading"> Agregando ...</span>
  </button>
</template>

<script>
export default {
  emits: ['update:add'],
  props: {
    text: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.container-cms .btn.btn-fab {
  bottom: 1rem;
  font-size: 1.2rem;
  padding: 1.3rem 1.5rem;
  position: fixed;
  right: 1.5rem;
}
</style>
